const templateHTML = `<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=unicode">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="선별검사보고서_template_수정본%20(1).fld/filelist.xml">
<link rel=Edit-Time-Data href="선별검사보고서_template_수정본%20(1).fld/editdata.mso">
<!--[if !mso]>
<style>
v\:* {behavior:url(#default#VML);}
o\:* {behavior:url(#default#VML);}
w\:* {behavior:url(#default#VML);}
.shape {behavior:url(#default#VML);}
</style>
<![endif]-->
<title>Speech4All • 강원특별자치도 춘천시 한림대학길 1 생명과학관 8606호 • www.speech4all.kr All
rights reserved. Copyright © Speech4All</title>
<link rel=themeData href="선별검사보고서_template_수정본%20(1).fld/themedata.thmx">
<link rel=colorSchemeMapping
href="선별검사보고서_template_수정본%20(1).fld/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>183</w:Zoom>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-US</w:LidThemeOther>
  <w:LidThemeAsian>KO</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SplitPgBreakAndParaMark/>
   <w:UseFELayout/>
  </w:Compatibility>
  <w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:굴림;
	panose-1:2 11 6 0 0 1 1 1 1 1;
	mso-font-alt:Gulim;
	mso-font-charset:129;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-1342176593 1775729915 48 0 524447 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536870145 1107305727 0 0 415 0;}
@font-face
	{font-family:"맑은 고딕";
	panose-1:2 11 5 3 2 0 0 2 0 4;
	mso-font-charset:129;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-1879048145 701988091 18 0 524289 0;}
@font-face
	{font-family:NanumGothic;
	panose-1:0 0 0 0 0 0 0 0 0 0;
	mso-font-charset:129;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:-2147483645 165149931 16 0 524289 0;}
@font-face
	{font-family:"\@맑은 고딕";
	mso-font-charset:129;
	mso-generic-font-family:auto;
	mso-font-pitch:auto;
	mso-font-signature:0 0 0 0 0 0;}
@font-face
	{font-family:함초롬돋움;
	panose-1:2 11 6 4 2 2 2 2 2 4;
	mso-font-alt:"맑은 고딕";
	mso-font-charset:0;
	mso-generic-font-family:auto;
	mso-font-pitch:auto;
	mso-font-signature:0 0 0 0 0 0;}
@font-face
	{font-family:"한컴 고딕";
	panose-1:2 11 6 4 2 2 2 2 2 4;
	mso-font-alt:Calibri;
	mso-font-charset:0;
	mso-generic-font-family:auto;
	mso-font-pitch:auto;
	mso-font-signature:0 0 0 0 0 0;}
@font-face
	{font-family:"\@NanumGothic";
	mso-font-charset:0;
	mso-generic-font-family:auto;
	mso-font-pitch:auto;
	mso-font-signature:0 0 0 0 0 0;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536870145 1073743103 0 0 415 0;}
@font-face
	{font-family:"\@굴림";
	panose-1:2 11 6 0 0 1 1 1 1 1;
	mso-font-charset:129;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-1342176593 1775729915 48 0 524447 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin:0cm;
	mso-pagination:widow-orphan;
	text-autospace:none;
	font-size:11.0pt;
	font-family:함초롬돋움;
	mso-fareast-font-family:굴림;
	mso-bidi-font-family:굴림;}
h1
	{mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:"제목 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:22.7pt;
	text-indent:-17.3pt;
	line-height:22.6pt;
	mso-pagination:widow-orphan;
	mso-outline-level:1;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"한컴 고딕";
	mso-fareast-font-family:굴림;
	mso-bidi-font-family:굴림;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"바닥글 Char";
	margin:0cm;
	mso-pagination:widow-orphan;
	layout-grid-mode:char;
	text-autospace:none;
	font-size:11.0pt;
	font-family:함초롬돋움;
	mso-fareast-font-family:굴림;
	mso-bidi-font-family:굴림;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-priority:99;
	mso-style-link:"본문 Char";
	margin:0cm;
	mso-pagination:widow-orphan;
	text-autospace:none;
	font-size:10.0pt;
	font-family:함초롬돋움;
	mso-fareast-font-family:굴림;
	mso-bidi-font-family:굴림;}
span.1Char
	{mso-style-name:"제목 1 Char";
	mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"제목 1";
	font-family:"맑은 고딕",sans-serif;
	mso-ascii-font-family:"맑은 고딕";
	mso-fareast-font-family:"맑은 고딕";
	mso-hansi-font-family:"맑은 고딕";}
p.msonormal0, li.msonormal0, div.msonormal0
	{mso-style-name:msonormal;
	mso-style-unhide:no;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"굴림",sans-serif;
	mso-bidi-font-family:굴림;}
span.Char
	{mso-style-name:"바닥글 Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:바닥글;
	font-family:함초롬돋움;
	mso-ascii-font-family:함초롬돋움;
	mso-hansi-font-family:함초롬돋움;}
span.Char0
	{mso-style-name:"본문 Char";
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:본문;
	font-family:함초롬돋움;
	mso-ascii-font-family:함초롬돋움;
	mso-hansi-font-family:함초롬돋움;}
p.msochpdefault, li.msochpdefault, div.msochpdefault
	{mso-style-name:msochpdefault;
	mso-style-unhide:no;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"맑은 고딕",sans-serif;
	mso-bidi-font-family:굴림;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-size:10.0pt;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;
	mso-ascii-font-family:"맑은 고딕";
	mso-fareast-font-family:"맑은 고딕";
	mso-hansi-font-family:"맑은 고딕";
	mso-font-kerning:0pt;
	mso-ligatures:none;}
@page WordSection1
	{size:595.0pt 841.0pt;
	margin:55.0pt 21.0pt 14.0pt 37.0pt;
	mso-header-margin:42.55pt;
	mso-footer-margin:49.6pt;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"표준 표";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
	mso-para-margin:0cm;
	mso-pagination:widow-orphan;
	font-size:10.0pt;
	font-family:"맑은 고딕",sans-serif;}
</style>
<![endif]-->
</head>

<body lang=KO style='tab-interval:40.0pt;word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoBodyText style='line-height:38.95pt;punctuation-wrap:simple'><b><span
lang=EN-US style='font-size:22.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria;color:white;background:#315E97'>&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:22.0pt;font-family:NanumGothic;color:white;
background:#315E97'> </span></b><b><span lang=EN-US style='font-size:22.0pt;
font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
Cambria;color:white;background:#315E97'>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:22.0pt;font-family:NanumGothic;color:white;background:#315E97'>언어기반
문해기초 선별 체크리스트 결과보고서</span></b><b><span lang=EN-US style='font-size:22.0pt;
font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
Cambria;color:white;background:#315E97'>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:22.0pt;font-family:NanumGothic;color:white;
background:#315E97'> </span></b><b><span lang=EN-US style='font-size:22.0pt;
font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
Cambria;color:white;background:#315E97'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><span
lang=EN-US style='font-size:10.5pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><b><span
style='font-size:12.0pt;font-family:NanumGothic;color:#1F4E79'>언어기반 문해기초 선별 체크리스트는</span></b><span
style='font-size:12.0pt;font-family:NanumGothic'> </span><span
style='font-size:11.0pt;font-family:NanumGothic'>학령전기 만<span lang=EN-US> 5-6</span>세
아동의 말<span lang=EN-US>-</span>언어 발달과 문해기초 능력을 조기에 선별하고자 개발되었습니다<span
lang=EN-US>. </span>선별 체크리스트 결과를 바탕으로 말<span lang=EN-US>-</span>언어발달지연 아동을 조기에 확인할
수 있는 심층검사를 제고하고<span lang=EN-US>, </span>예방적 차원에서의 조기 중재 기틀을 마련하고자 합니다<span
lang=EN-US>. </span></span></p>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><span
lang=EN-US style='font-size:11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><span
lang=EN-US style='font-size:11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span><span style='font-size:
11.0pt;font-family:NanumGothic'>유치원 또는 가정에서 아동의 언어발달 수준을 관찰한 결과를 토대로 체크리스트를 작성하는
방식으로 진행되었습니다<span lang=EN-US>. </span>주의해야 할 점은 보고자의 응답에 따라 언어발달이 다소 과소 또는 과대 평가될
가능성이 있습니다<span lang=EN-US>. </span></span><span lang=EN-US style='font-size:
11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;
mso-bidi-font-family:Cambria'>&nbsp;</span><span style='font-size:11.0pt;
font-family:NanumGothic'>이를 감안하여 결과를 해석하고<span lang=EN-US>, </span>말<span
lang=EN-US>, </span>언어<span lang=EN-US>, </span><span class=SpellE>초기문해</span> 발달이
위험군에 해당하는 아동들에게는 </span></p>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><span
style='font-size:11.0pt;font-family:NanumGothic'>추가적인 심화 평가를 권고 드립니다<span
lang=EN-US>. </span></span></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><span lang=EN-US
style='font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;
mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<h1 style='margin-top:.4pt;punctuation-wrap:simple'><span lang=EN-US
style='color:#315E97;font-weight:normal'>□</span><span lang=EN-US
style='font-size:7.0pt;font-family:"Times New Roman",serif;color:#315E97;
font-weight:normal'>&nbsp;&nbsp; </span><span style='font-size:12.5pt;
font-family:NanumGothic;color:#315E97'>검사 실시</span></h1>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:34.05pt'>
  <td width=111 style='width:83.4pt;border:dotted windowtext 1.0pt;border-left:
  dotted white 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>지
  </span></b><b><span lang=EN-US style='font-family:"Cambria",serif;mso-fareast-font-family:
  NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></b><b><span
  style='font-family:NanumGothic'>역</span></b></p>
  </td>
  <td width=123 style='width:92.1pt;border:dotted windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText align=center style='margin-top:.7pt;text-align:center;
  punctuation-wrap:simple'><span lang=EN-US style='font-size:8.0pt;font-family:
  "Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
  Cambria'>{LOCATION}&nbsp;</span></p>
  </td>
  <td width=95 style='width:70.9pt;border:dotted windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>기관명</span></b></p>
  </td>
  <td width=132 style='width:99.2pt;border:dotted windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoNormal align=center style='text-align:center'><span lang=EN-US
  style='font-size:8.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
  NanumGothic;mso-bidi-font-family:Cambria'>{ORGANIZE}</span></p>
  </td>
  <td width=95 style='width:70.9pt;border:dotted windowtext 1.0pt;border-left:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>교사이름</span></b></p>
  </td>
  <td width=174 style='width:130.4pt;border-top:dotted windowtext 1.0pt;
  border-left:none;border-bottom:dotted windowtext 1.0pt;border-right:dotted white 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText align=center style='margin-top:.7pt;text-align:center;
  punctuation-wrap:simple'><span lang=EN-US style='font-size:8.0pt;font-family:
  "Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
  Cambria'>&nbsp;{TEACHERNAME}</span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:34.05pt'>
  <td width=111 style='width:83.4pt;border-top:none;border-left:dotted white 1.0pt;
  border-bottom:dotted windowtext 1.0pt;border-right:dotted windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>작성일</span></b></p>
  </td>
  <td width=123 style='width:92.1pt;border-top:none;border-left:none;
  border-bottom:dotted windowtext 1.0pt;border-right:dotted windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText align=center style='margin-top:.7pt;text-align:center;
  punctuation-wrap:simple'><span lang=EN-US style='font-size:8.0pt;font-family:
  "Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
  Cambria'>&nbsp;&nbsp;{DATE}</span></p>
  </td>
  <td width=95 style='width:70.9pt;border-top:none;border-left:none;border-bottom:
  dotted windowtext 1.0pt;border-right:dotted windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;
  height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>성</span></b><b><span
  lang=EN-US style='font-family:"Cambria",serif;mso-fareast-font-family:NanumGothic;
  mso-bidi-font-family:Cambria'>&nbsp;</span></b><b><span lang=EN-US
  style='font-family:NanumGothic'> </span></b><b><span style='font-family:NanumGothic'>별</span></b></p>
  </td>
  <td width=132 style='width:99.2pt;border-top:none;border-left:none;
  border-bottom:dotted windowtext 1.0pt;border-right:dotted windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText align=center style='margin-top:.7pt;text-align:center;
  punctuation-wrap:simple'><span lang=EN-US style='font-size:8.0pt;font-family:
  "Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
  Cambria'>&nbsp;{GENDER}</span></p>
  </td>
  <td width=95 style='width:70.9pt;border-top:none;border-left:none;border-bottom:
  dotted windowtext 1.0pt;border-right:dotted windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;
  height:34.05pt'>
  <p class=MsoBodyText style='margin-top:.7pt;text-align:justify;text-justify:
  inter-ideograph;punctuation-wrap:simple'><b><span style='font-family:NanumGothic'>아동이름</span></b></p>
  </td>
  <td width=174 style='width:130.4pt;border-top:none;border-left:none;
  border-bottom:dotted windowtext 1.0pt;border-right:dotted white 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:34.05pt'>
  <p class=MsoBodyText align=center style='margin-top:.7pt;text-align:center;
  punctuation-wrap:simple'><span lang=EN-US style='font-size:8.0pt;font-family:
  "Cambria",serif;mso-fareast-font-family:NanumGothic;mso-bidi-font-family:
  Cambria'>&nbsp;{CHILDENNAME}</span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText style='margin-top:.7pt;punctuation-wrap:simple'><span
lang=EN-US style='font-size:8.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<h1 style='margin-top:.4pt;punctuation-wrap:simple'><span lang=EN-US
style='color:#315E97;font-weight:normal'>□</span><span lang=EN-US
style='font-size:7.0pt;font-family:"Times New Roman",serif;color:#315E97;
font-weight:normal'>&nbsp;&nbsp; </span><span style='font-size:12.5pt;
font-family:NanumGothic;color:#315E97'>검사 결과</span></h1>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
 style='border-collapse:collapse;mso-yfti-tbllook:1184;mso-table-lspace:7.1pt;
 margin-left:4.85pt;mso-table-rspace:7.1pt;margin-right:4.85pt;mso-table-anchor-vertical:
 paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;mso-padding-alt:
 0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:48.15pt'>
  <td width=158 style='width:118.8pt;border:solid #2E74B5 1.5pt;border-bottom:
  solid #2E74B5 1.0pt;background:#1F4E79;padding:0cm 5.4pt 0cm 5.4pt;
  height:48.15pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-height-rule:exactly'><b><span style='font-size:11.0pt;font-family:NanumGothic;
  color:white'>총점</span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:33.85pt'>
  <td width=158 style='width:118.8pt;border:solid #2E74B5 1.5pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:33.85pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-height-rule:exactly'><span lang=EN-US>{TOTALSCORE}</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal><span lang=EN-US style='display:none;mso-hide:all'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=right
 width=491 style='width:13.0cm;border-collapse:collapse;mso-yfti-tbllook:1184;
 mso-table-lspace:7.1pt;margin-left:4.85pt;mso-table-rspace:7.1pt;margin-right:
 4.85pt;mso-table-anchor-vertical:paragraph;mso-table-anchor-horizontal:column;
 mso-table-left:right;mso-table-top:middle;mso-padding-alt:0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:23.35pt'>
  <td width=491 colspan=2 style='width:13.0cm;border:solid #2E74B5 1.5pt;
  border-bottom:solid #2E74B5 1.0pt;background:#1F4E79;padding:0cm 5.4pt 0cm 5.4pt;
  height:23.35pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-element-left:right;mso-element-top:middle;mso-height-rule:exactly'><span
  class=SpellE><b><span style='font-size:11.0pt;font-family:NanumGothic;
  color:white'>영역별</span></b></span><b><span style='font-size:11.0pt;
  font-family:NanumGothic;color:white'> 세부 점수</span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1;height:23.35pt'>
  <td width=246 style='width:184.25pt;border-top:none;border-left:solid #2E74B5 1.5pt;
  border-bottom:solid #2E74B5 1.0pt;border-right:solid #2E74B5 1.0pt;
  background:#1F4E79;padding:0cm 5.4pt 0cm 5.4pt;height:23.35pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-element-left:right;mso-element-top:middle;mso-height-rule:exactly'><b><span
  style='font-size:11.0pt;font-family:NanumGothic;color:white'>언어 이해 및 표현</span></b></p>
  </td>
  <td width=246 style='width:184.3pt;border-top:none;border-left:none;
  border-bottom:solid #2E74B5 1.0pt;border-right:solid #2E74B5 1.5pt;
  background:#1F4E79;padding:0cm 5.4pt 0cm 5.4pt;height:23.35pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-element-left:right;mso-element-top:middle;mso-height-rule:exactly'><b><span
  style='font-size:11.0pt;font-family:NanumGothic;color:white'>말 및 문해</span></b></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:33.85pt'>
  <td width=246 style='width:184.25pt;border-top:none;border-left:solid #2E74B5 1.5pt;
  border-bottom:solid #2E74B5 1.5pt;border-right:solid #2E74B5 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:33.85pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-element-left:right;mso-element-top:middle;mso-height-rule:exactly'><span
  lang=EN-US>{SCOREA}</span></p>
  </td>
  <td width=246 style='width:184.3pt;border-top:none;border-left:none;
  border-bottom:solid #2E74B5 1.5pt;border-right:solid #2E74B5 1.5pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:33.85pt'>
  <p class=MsoBodyText align=center style='margin-top:.35pt;margin-right:0cm;
  margin-bottom:.05pt;margin-left:0cm;text-align:center;punctuation-wrap:simple;
  mso-element:frame;mso-element-frame-hspace:7.1pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-element-left:right;mso-element-top:middle;mso-height-rule:exactly'><span
  lang=EN-US>{SCOREB}</span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other'><span
lang=EN-US style='font-size:12.0pt;font-family:"굴림",sans-serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other'><span
lang=EN-US style='font-size:12.0pt;font-family:"굴림",sans-serif'><o:p>&nbsp;</o:p></span></p>

<p class=MsoBodyText style='margin-top:.35pt;margin-right:0cm;margin-bottom:
.05pt;margin-left:0cm;punctuation-wrap:simple'><b><span lang=EN-US
style='font-size:13.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria;color:#1F4E79'>&nbsp;</span></b></p>

<p class=MsoBodyText style='margin-top:.35pt;margin-right:0cm;margin-bottom:
.05pt;margin-left:0cm;punctuation-wrap:simple'><b><span lang=EN-US
style='font-size:13.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria;color:#1F4E79'>&nbsp;</span></b></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><b><span style='font-size:
11.0pt;font-family:NanumGothic'>▶<span style='color:#00B050'> 양호 </span></span></b><span
lang=EN-US style='font-size:11.0pt;font-family:NanumGothic'>: </span><span
style='font-size:11.0pt;font-family:NanumGothic'>또래와 비슷한 수준으로 언어발달이 잘 이루어지고 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><span lang=EN-US
style='font-size:11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><b><span style='font-size:
11.0pt;font-family:NanumGothic'>▶<span style='color:#ED7D31'> 주의 </span></span></b><span
lang=EN-US style='font-size:11.0pt;font-family:NanumGothic'>: </span><span
style='font-size:11.0pt;font-family:NanumGothic'>또래에 비해 다소 느린 수준으로<span
lang=EN-US>, </span>아동의 발달이 잘 이루어지는지 관심을 갖고 면밀히 살펴 주세요<span lang=EN-US>. </span>이후
발달이 긍정적으로 변화하였는지 지속적으로 모니터링해주세요<span lang=EN-US>.</span></span></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><span lang=EN-US
style='font-size:11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<p class=MsoBodyText style='punctuation-wrap:simple'><b><span style='font-size:
11.0pt;font-family:NanumGothic'>▶<span style='color:red'> 위험 </span></span></b><span
lang=EN-US style='font-size:11.0pt;font-family:NanumGothic'>: </span><span
style='font-size:11.0pt;font-family:NanumGothic'>또래에 비해 느린 수준으로<span
lang=EN-US>, </span>전문적인 언어 평가를 통하여 아동의 수행 수준을 명확하게 파악할 필요가 있습니다<span
lang=EN-US>. </span></span></p>

<p class=MsoBodyText style='margin-top:.35pt;punctuation-wrap:simple'><span
lang=EN-US style='font-size:11.0pt;font-family:"Cambria",serif;mso-fareast-font-family:
NanumGothic;mso-bidi-font-family:Cambria'>&nbsp;</span></p>

<h1 style='margin-top:.4pt;punctuation-wrap:simple'><span lang=EN-US
style='color:#315E97;font-weight:normal'>□</span><span lang=EN-US
style='font-size:7.0pt;font-family:"Times New Roman",serif;color:#315E97;
font-weight:normal'>&nbsp;&nbsp; </span><span style='font-size:11.0pt;
font-family:NanumGothic;color:#315E97'>권고사항</span></h1>

<p class="MsoBodyText" style="margin: 0; text-align: justify; text-indent: 10px; line-height: 1.5;">
  <span style='font-size:11.0pt;font-family:NanumGothic'>
    {RECOMMENDATION}
  </span>
</p>

<h1 style='punctuation-wrap:simple'><span lang=EN-US style='color:#315E97;
font-weight:normal'>□</span><span lang=EN-US style='font-size:7.0pt;font-family:
"Times New Roman",serif;color:#315E97;font-weight:normal'>&nbsp;&nbsp; </span><span
style='font-size:11.0pt;font-family:NanumGothic;color:#315E97'>문의<span
style='letter-spacing:2.8pt'> </span>사항</span></h1>

<p class=MsoBodyText style='margin-left:22.95pt;line-height:17.4pt;punctuation-wrap:
simple'><span style='font-size:11.0pt;font-family:NanumGothic;color:#404040'>본 보고서와
관련하여 문의 사항이 있으실 경우 아래의 연락처로 연락 바랍니다<span lang=EN-US>.</span></span></p>

<p class=MsoBodyText style='margin-top:.4pt;margin-right:0cm;margin-bottom:
0cm;margin-left:22.95pt;margin-bottom:.0001pt;punctuation-wrap:simple'><span
style='font-size:11.0pt;font-family:NanumGothic;color:#404040'>전화 상담의 경우<span
lang=EN-US>, </span></span><span style='font-size:11.0pt;font-family:NanumGothic;
color:#315E97'>유치원명<span lang=EN-US>/</span><span class=SpellE>학생명</span><span
lang=EN-US>/</span>통화가능한시간</span><span style='font-size:11.0pt;font-family:
NanumGothic;color:#404040'>을 문자메시지로 <span class=SpellE>남겨주시면</span><span
style='letter-spacing:2.95pt'> </span><span class=SpellE>연락드리겠습니다</span><span
lang=EN-US>.</span></span></p>

<p class=MsoBodyText style='margin-top:.35pt;margin-right:0cm;margin-bottom:
0cm;margin-left:22.95pt;margin-bottom:.0001pt;punctuation-wrap:simple'><b><span
lang=EN-US style='font-size:11.0pt;font-family:NanumGothic;color:#404040'>E-mail
</span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Cambria",serif;
mso-fareast-font-family:NanumGothic;mso-bidi-font-family:Cambria;color:#404040'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:11.0pt;font-family:NanumGothic;color:#404040'>T </span></b><span
style='font-size:11.0pt;font-family:NanumGothic;color:#404040'>사업담당자 </span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
 style='mso-cellspacing:0cm;mso-yfti-tbllook:1184;mso-table-lspace:2.25pt;
 mso-table-rspace:2.25pt;mso-table-anchor-vertical:paragraph;mso-table-anchor-horizontal:
 column;mso-table-left:left;mso-padding-alt:0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td width=42 style='width:31.5pt;padding:0cm 0cm 0cm 0cm'></td>
  <td width=114 style='width:85.5pt;padding:0cm 0cm 0cm 0cm'></td>
  <td width=310 style='width:232.5pt;padding:0cm 0cm 0cm 0cm'></td>
  <td width=83 style='width:62.25pt;padding:0cm 0cm 0cm 0cm'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:28.0pt'>
  <td style='padding:0cm 0cm 0cm 0cm;height:28.0pt'></td>
  <td valign=top style='padding:0cm 0cm 0cm 0cm;height:28.0pt'>
  <p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other;
  mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-height-rule:exactly'><span lang=EN-US style='font-size:12.0pt;font-family:
  "굴림",sans-serif'><o:p></o:p></span></p>
  </td>
  <td style='padding:0cm 0cm 0cm 0cm;height:28.0pt'></td>
  <td valign=top style='padding:0cm 0cm 0cm 0cm;height:28.0pt'>
  <p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other;
  mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;
  mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:column;
  mso-height-rule:exactly'><span lang=EN-US style='font-size:12.0pt;font-family:
  "굴림",sans-serif'><o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoBodyText style='margin-top:.6pt;punctuation-wrap:simple'><span
lang=EN-US><o:p>&nbsp;</o:p></span></p>

</div>

</body>

</html>
`;

module.exports = { templateHTML };
