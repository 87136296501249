import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { H1, H2, H3, H4 } from "../../component/Typography";
import { Col, Descriptions, Divider, Pagination, Row, Space } from "antd";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton, SecondaryButton } from "../../component/Button";
import axios from "axios";
import { templateHTML } from "../../template";

const convertToRtfUnicode = (text) => {
  return text
    .split("")
    .map((char) => `\\u${char.charCodeAt(0)}`)
    .join("");
};

const formatExamDate = (examDate) => {
  const date = new Date(examDate.$d); // dayjs 객체에서 실제 Date 객체를 추출
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월을 두 자리로 맞추기
  const day = String(date.getDate()).padStart(2, "0"); // 일을 두 자리로 맞추기

  // YYYY-MM-DD 형식으로 반환
  return `${year}-${month}-${day}`;
};

//------------------------------------------------------------------------------
// JUNGMIN: 추가
const getEvaluation = (score, thresholds) => {
  if (score >= thresholds.good) return "양호";
  if (score >= thresholds.warning) return "주의";
  return "위험";
};

function Analysis(props) {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [rtfContent, setRtfContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("location.state:", location.state);
    if (location.state) {
      insertDataToFile(location.state);
    }
  }, [location.state]); // location.state가 변경될 때마다 호출

  const { categoryAScore, categoryBScore } = location.state;
  const totalScore = categoryAScore + categoryBScore;

  // ✅ 점수 평가 기준
  const totalEvaluation = getEvaluation(totalScore, { good: 24, warning: 20 });
  const categoryAEvaluation = getEvaluation(categoryAScore, {
    good: 10,
    warning: 7,
  });
  const categoryBEvaluation = getEvaluation(categoryBScore, {
    good: 14,
    warning: 12,
  });

  const generateRecommendationHTML = (
    totalEvaluation,
    categoryAEvaluation,
    categoryBEvaluation
  ) => {
    // 어느 한 영역이라도 "위험"이 나온 경우
    if (
      totalEvaluation === "위험" ||
      categoryAEvaluation === "위험" ||
      categoryBEvaluation === "위험"
    ) {
      return `
        본 아동은 언어 선별 검사결과 또래보다 느린 수준으로 아동의 언어발달 수준을
        파악하기 위하여 <b style="color: red;">전문적인 심화 언어평가를 권고드립니다.</b>
        언어발달 전문가와의 심층적인 언어평가 결과를 바탕으로 진행하는 컨설팅을 통해
        아동의 강점과 약점을 파악하고 이에 대한 전문적인 지원을 받으시길 바랍니다.
        본 아동은 심화언어검사를 통해 직접적인 언어발달 지원 및 치료 서비스를 받고,
        초등학교 생활과 학습을 성공적으로 준비할 수 있기를 바랍니다.
      `;
    }

    // 총점이 "주의"이고, 모든 영역이 "주의"인 경우
    if (
      totalEvaluation === "주의" &&
      categoryAEvaluation === "주의" &&
      categoryBEvaluation === "주의"
    ) {
      return `
        본 아동은 언어 선별 검사결과 <span style="color: orange;">언어 이해 및 표현, 말 및 초기 문해 영역</span>에서
        ‘주의’가 나와 또래에 비해 <span stype="color:green";>다소 느린 발달</span>을 보이는 것으로 나타났습니다.
        아동의 발달이 잘 이루어지는지 관심을 갖고 면밀히 살펴봐 주시기 바랍니다.
        놀이와 책을 활용하여 정확한 소리로 낱말을 표현하고, 한글에 대한 관심을 갖도록
        촉진해주시기 바랍니다. 이후 아동의 언어 이해 및 표현, 말 및 초기문해 능력이
        지속적으로 발달하여 초등학교 생활과 학습을 성공적으로 준비할 수 있기를 바랍니다.
      `;
    }

    // 총점이 "양호"이고, 언어 이해 및 표현 영역이 "주의", 말 및 문해는 "양호"인 경우
    if (
      totalEvaluation === "양호" &&
      categoryAEvaluation === "주의" &&
      categoryBEvaluation === "양호"
    ) {
      return `
        본 아동은 언어 선별 검사결과 또래 수준으로 <span style="color: green;">양호한 언어발달</span>을 보입니다.
        하지만 <span style="color: orange;">언어 이해 및 표현 영역</span>에서 ‘주의’로 나타났습니다.
        해당 영역에서 아동 발달이 잘 이루어지는지 관심을 갖고 면밀히 살펴봐 주시고,
        놀이와 책을 활용하여 언어 이해 및 표현이 향상될 수 있도록 촉진해주시기 바랍니다.
        이후 아동의 언어 이해 및 표현, 말 및 초기문해 능력이 지속적으로 발달하여
        초등학교 생활과 학습을 성공적으로 준비할 수 있기를 바랍니다.
      `;
    }

    // 총점이 "양호"이고, 언어 이해 및 표현 영역이 "양호", 말 및 문해는 "주의"인 경우
    if (
      totalEvaluation === "양호" &&
      categoryAEvaluation === "양호" &&
      categoryBEvaluation === "주의"
    ) {
      return `
        본 아동은 언어 선별 검사결과 또래 수준으로 <span style="color: green;">양호한 언어발달</span>을 보입니다.
        하지만 <span style="color: orange;">말 및 초기문해 영역</span>에서 ‘주의’로 나타났습니다.
        해당 영역에서 아동 발달이 잘 이루어지는지 관심을 갖고 면밀히 살펴봐 주시고,
        놀이와 책을 활용하여 정확한 소리로 낱말을 표현하고, 한글에 대한 관심을 갖도록
        촉진해주시기 바랍니다. 이후 아동의 언어 이해 및 표현, 말 및 초기문해 능력이
        지속적으로 발달하여 초등학교 생활과 학습을 성공적으로 준비할 수 있기를 바랍니다.
      `;
    }

    // 모든 영역이 "양호"인 경우 (양호한 언어발달 부분만 초록색 강조)
    return `
      본 아동은 언어 선별 검사결과 또래 수준으로 <span style="color: green;">양호한 언어발달</span>을 보입니다.
      계속해서 아동의 언어발달 촉진에 좋은 놀이와 책을 활용하여 적극적으로
      의사소통해주시기 바랍니다. 이후 아동의 언어 이해 및 표현, 말 및 초기문해
      능력이 지속적으로 발달하여 초등학교 생활과 학습을 성공적으로 준비할 수
      있기를 바랍니다.
    `;
  };

  // 여러 템플릿 변수에 데이터를 삽입하는 함수
  const insertDataToFile = async (data) => {
    try {
      const response = await fetch("/template.rtf");
      const content = await response.text();

      // 템플릿 내 변수들에 해당 데이터를 삽입
      let updatedContent = content;

      // 여러 변수를 하나씩 처리 (여기서는 예시로 CHILDENNAME, PARENTSNAME을 사용)
      updatedContent = updatedContent.replace(
        "{LOCATION}",
        convertToRtfUnicode(data.info.region)
      );
      updatedContent = updatedContent.replace(
        "{ORGANIZE}",
        convertToRtfUnicode(data.info.institution)
      );
      updatedContent = updatedContent.replace(
        "{TEACHERNAME}",
        convertToRtfUnicode(data.info.examiner)
      );
      updatedContent = updatedContent.replace(
        "{DATE}",
        formatExamDate(data.info.examdate)
      );
      updatedContent = updatedContent.replace(
        "{GENDER}",
        convertToRtfUnicode(data.info.gender)
      );
      updatedContent = updatedContent.replace(
        "{CHILDENNAME}",
        convertToRtfUnicode(data.info.name)
      );
      updatedContent = updatedContent.replace(
        "{TOTALSCORE}",
        data.categoryAScore + data.categoryBScore
      );
      updatedContent = updatedContent.replace("{SCOREA}", data.categoryAScore);
      updatedContent = updatedContent.replace("{SCOREB}", data.categoryBScore);

      // ✅ HTML 포함된 평가 문구 삽입
      updatedContent = updatedContent.replace(
        "{RECOMMENDATION}",
        generateRecommendationHTML(
          totalEvaluation,
          categoryAEvaluation,
          categoryBEvaluation
        )
      );

      setRtfContent(updatedContent); // 업데이트된 RTF 콘텐츠 상태에 설정
    } catch (error) {
      console.error("파일 로드 실패:", error);
    }

    try {
      let updatedContent = templateHTML;

      updatedContent = updatedContent.replace("{LOCATION}", data.info.region);
      updatedContent = updatedContent.replace(
        "{ORGANIZE}",
        data.info.institution
      );
      updatedContent = updatedContent.replace(
        "{TEACHERNAME}",
        data.info.examiner
      );
      updatedContent = updatedContent.replace(
        "{DATE}",
        formatExamDate(data.info.examdate)
      );
      updatedContent = updatedContent.replace("{GENDER}", data.info.gender);
      updatedContent = updatedContent.replace("{CHILDENNAME}", data.info.name);
      updatedContent = updatedContent.replace(
        "{TOTALSCORE}",
        data.categoryAScore + data.categoryBScore
      );
      updatedContent = updatedContent.replace("{SCOREA}", data.categoryAScore);
      updatedContent = updatedContent.replace("{SCOREB}", data.categoryBScore);

      // ✅ HTML 포함된 평가 문구 삽입
      updatedContent = updatedContent.replace(
        "{RECOMMENDATION}",
        generateRecommendationHTML(
          totalEvaluation,
          categoryAEvaluation,
          categoryBEvaluation
        )
      );

      setHtmlContent(updatedContent);
    } catch (error) {
      console.error("HTML 변환 오류:", error);
    }
  };

  const downloadRTF = () => {
    if (!rtfContent) {
      console.error("RTF 콘텐츠가 없습니다.");
      return;
    }

    // RTF 파일을 Blob으로 생성하여 다운로드
    const blob = new Blob([rtfContent], { type: "application/rtf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "report.rtf"; // 다운로드 파일 이름
    link.click(); // 자동으로 다운로드 시작
    URL.revokeObjectURL(link.href); // 메모리 해제
  };

  return (
    <PageContainer>
      <H3>결과 요약</H3>
      <Row style={{ alignItems: "center" }}>
        <Col span={8}>
          <TableHeader>총점</TableHeader>
        </Col>
        <Col span={16}>
          <TableHeader>영역별 세부점수</TableHeader>
        </Col>
        <Col span={8}>
          <TableDescriptions style={getStyleByEvaluation(totalEvaluation)}>
            {totalEvaluation}
          </TableDescriptions>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={12}>
              <TableDescriptions style={{ fontWeight: "bold" }}>
                언어 이해 및 표현
              </TableDescriptions>
            </Col>
            <Col span={12}>
              <TableDescriptions style={{ fontWeight: "bold" }}>
                말 및 문해
              </TableDescriptions>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <TableDescriptions
                style={getStyleByEvaluation(categoryAEvaluation)}
              >
                {categoryAEvaluation}
              </TableDescriptions>
            </Col>
            <Col span={12}>
              <TableDescriptions
                style={getStyleByEvaluation(categoryBEvaluation)}
              >
                {categoryBEvaluation}
              </TableDescriptions>
            </Col>
          </Row>
        </Col>
      </Row>

      <PreviewContainer
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          textAlign: "left",
          padding: "24px",
          border: "1px solid black",
        }}
      />
    </PageContainer>
  );
}

// ✅ 평가 결과별 스타일 지정 함수
const getStyleByEvaluation = (evaluation) => {
  if (evaluation === "양호") {
    return { backgroundColor: "rgba(52, 199, 89, 0.1)", color: "green" };
  }
  if (evaluation === "주의") {
    return { backgroundColor: "rgba(255, 204, 0, 0.1)", color: "orange" };
  }
  return { backgroundColor: "rgba(255, 59, 48, 0.1)", color: "red" };
};

const PreviewContainer = styled.div``;

const PageContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 64px 24px;
  box-sizing: border-box;
  max-width: 880px;
  margin: 0 auto;
  gap: 24px;
`;

const TableHeader = styled.div`
  height: 48px;
  background-color: var(--bg-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const TableDescriptions = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: var(--bg-disable);
  gap: 16px;
`;

const SideComponent = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ align }) => align || "flex-start"};
  width: 100%;
`;

export default Analysis;
